body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title{
  text-align: center;
  background-color: yellow;
  width: 80%
}

.backBtn{
  text-align: center;
  background-color: yellow;
  width: 20%
}

.menu{
  text-align: center;
  background-color: #1c3455;
  color: #FFF;
}

.legoList{
  text-align: center;
  background-color: #1c3455;
  color: #FFF;
  padding: 0px 0px;
}

.LegoDetail{
  margin: 10px auto;
  width: 1000px;
  font-size: 20px;
  font-weight: bold;
}

.LegoDetail img{
  width: 150px;
}

.container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
  width: 95%;
  max-width: 1020px;
}
.containerItem{
  border: 3px solid #f7f7f7;
  border-radius: 7px;
  width: 300px;
  margin: 5px;
  text-align: center;
  background-color: antiquewhite;
  padding: 10px 10px;
}
